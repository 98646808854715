import { useNavigate } from 'react-router-dom';
import { cattlePO } from '../../apps/shippingTracker/featureFlags/cattlePOFeatures';

const SidebarLogoFM = (props: any) => {
    const navigate = useNavigate();
    const handleClick = () => navigate(`/`);
    const { isBP828On_inconsistentLogoBug } = cattlePO;
    const logo = isBP828On_inconsistentLogoBug
        ? 'sidebar_fm.png'
        : 'sidebar_fm_old.png';
    return (
        <img
            {...props}
            alt="sidebar_fm.png"
            src={require(`./${logo}`)}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
            onClick={handleClick}
        />
    );
};

export default SidebarLogoFM;
