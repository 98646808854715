const DeleteIcon = (props: any) => {
    return (
        <img
            className="delete-icon cursor-pointer"
            {...props}
            alt="delete_icon.png"
            src={require('./delete_icon.png')}
        />
    );
};

export default DeleteIcon;
