import { useNavigate } from 'react-router-dom';
import {
    ChangeEventHandler,
    FormEventHandler,
    useEffect,
    useState,
} from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Constants from '../../../../../utils/Constants';
import useAuthContext from '../hooks/useAuthContext';
import { ICredentials, ICredentialsError } from '../interface/ICredentials';
import AuthPageContainer from '../components/AuthPageContainer';
import { useTranslation } from 'react-i18next';

export const LoginPage = () => {
    const { t } = useTranslation('auth');
    const { localAuthLogin, renewLocalToken, isAuthenticated } =
        useAuthContext();
    const navigate = useNavigate();

    const [isProcessing, setIsProcessing] = useState(false);
    const [credentials, setCredentials] = useState<ICredentials>({
        username: '',
        password: '',
    });
    const [credentialsError, setCredentialsError] = useState<ICredentialsError>(
        {
            username: false,
            password: false,
        },
    );

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
        const { name, value } = event.currentTarget;

        const updatedCredentials = { ...credentials, [name]: value };
        setCredentials(updatedCredentials);

        const updatedCredentialsError = {
            ...credentialsError,
            [name]: value === '',
        };
        setCredentialsError(updatedCredentialsError);
    };

    const validateForm = (): boolean => {
        const credentialsErrorCopy = { ...credentialsError };
        Object.keys(credentials).forEach(field => {
            credentialsErrorCopy[field] = credentials[field] === '';
        });
        setCredentialsError(credentialsErrorCopy);

        return Object.values(credentialsErrorCopy).every(
            error => error === false,
        );
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault();
        const isFormValid = validateForm();
        if (isFormValid) {
            setIsProcessing(true);
            localAuthLogin({
                username: credentials.username,
                password: credentials.password,
                redirectPath: Constants.Routes.FM_DASHBOARD,
                isTransient: false,
            });
            setIsProcessing(false);
        }
    };

    useEffect(() => {
        const token = renewLocalToken();

        if (token && isAuthenticated(true)) {
            navigate(Constants.Routes.FM_DASHBOARD);
        }
    }, [isAuthenticated, navigate, renewLocalToken]);

    return (
        <AuthPageContainer showNgatLogo={true}>
            <Form onSubmit={handleSubmit} noValidate className="px-4">
                <Col className="px-1">
                    <Form.Group>
                        <Form.Label className="mb-1">
                            {t('loginPage.email')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="username"
                            value={credentials.username}
                            onChange={handleChange}
                            className={
                                credentialsError.username ? 'border-danger' : ''
                            }
                            disabled={isProcessing}
                        />
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label className="mb-1">
                            {t('loginPage.password')}
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            className={
                                credentialsError.password ? 'border-danger' : ''
                            }
                            disabled={isProcessing}
                        />
                    </Form.Group>
                    <Row className="mt-3">
                        <a href={Constants.Routes.FORGOT_PASSWORD}>
                            {t('loginPage.forgotPassword')}
                        </a>
                    </Row>
                    <br />

                    <Button
                        type="submit"
                        size="lg"
                        variant="primary"
                        disabled={isProcessing}
                        className="fw-bold mb-1"
                    >
                        <div className="px-4">
                            {isProcessing
                                ? t('loginPage.loggingIn')
                                : t('loginPage.logIn')}
                        </div>
                    </Button>
                </Col>
            </Form>
        </AuthPageContainer>
    );
};

export default LoginPage;
