import { FC, FormEventHandler, PropsWithChildren } from 'react';
import { Button, Form } from 'react-bootstrap';
import AuthPageContainer from './AuthPageContainer';

interface IAuthFormContainer extends PropsWithChildren {
    heading: string;
    handleSubmit: FormEventHandler;
    buttonLabel: string;
    isButtonDisabled: boolean;
}

export const AuthFormContainer: FC<IAuthFormContainer> = ({
    heading,
    handleSubmit,
    buttonLabel,
    isButtonDisabled,
    children,
}) => {
    return (
        <AuthPageContainer showNgatLogo={false} widthInPx={614}>
            <Form onSubmit={handleSubmit} className="px-4">
                <div className="px-1">
                    <h3
                        className="block-heading fw-bold mb-4"
                        style={{ fontSize: '30px' }}
                    >
                        {heading}
                    </h3>
                    {children}
                    <div className="d-flex justify-content-end">
                        <Button
                            type="submit"
                            size="lg"
                            variant={isButtonDisabled ? 'secondary' : 'primary'}
                            disabled={isButtonDisabled}
                            className="fw-bold mt-4 mb-1"
                        >
                            <div className="px-4">{buttonLabel}</div>
                        </Button>
                    </div>
                </div>
            </Form>
        </AuthPageContainer>
    );
};
