import { Row, Accordion, Card, Table, Button } from 'react-bootstrap';
import Constants from '../../utils/Constants';
import { LegacyRef, useState } from 'react';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import AccordionLoading from '../common/AccordionLoading';
import { useElementOnScreen } from '../../commonHooks/useElementOnScreen';
import HospitalAdminHistoryHeader from './HospitalAdminHistoryHeader';
import { HospitalConstants } from '../../utils/constants/HospitalConstants';
import { useLanguage } from '../../commonHooks/useLocalization';
import {
    IHospitalListTable,
    IAnimal,
} from '../../utils/Interface/HospitalInterface';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { fetchAdminHistory } from '../../Redux/Actions/hospitalActions';
import { formatDate_deprecated } from '../../helpers';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import MoveFromHospitalPenModal from './MoveFromHospitalPenModal';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA10313On_HospitalPageUnassociatedHomePen } = feedlotManagerFeatures;

const HospitalListTable = ({
    onAccordionToggle,
    activeAccordionKey,
    penFilter,
}: IHospitalListTable) => {
    const { hospitalAnimalsList, animalAdminHistory, loadingHistory, loading } =
        useAppSelector(state => state.hospital);
    const feedlotId = useFeedlotId();
    const language = useLanguage();
    const dispatch = useAppDispatch();
    const [containerRef] = useElementOnScreen({
        ...Constants.useElementOnScreen.options,
    });
    const handleAccordionToggle = (key: AccordionEventKey) => {
        if (key === activeAccordionKey) {
            onAccordionToggle('');
        } else {
            onAccordionToggle(key);
        }
    };

    const handleAccordionFetchData = (animalId: string | number) => {
        if (feedlotId) {
            dispatch(fetchAdminHistory(feedlotId, animalId));
        }
    };

    const [currentlySelectedAnimal, setCurrentlySelectedAnimal] =
        useState<IAnimal>();

    const createMoveFromHospitalPenModal = useModalStatus();

    const openMoveModal = animal => {
        setCurrentlySelectedAnimal(animal);
        createMoveFromHospitalPenModal.open();
    };

    const closeModalHandler = () => {
        createMoveFromHospitalPenModal.close();
    };

    return (
        <>
            <MoveFromHospitalPenModal
                key={
                    isCA10313On_HospitalPageUnassociatedHomePen
                        ? currentlySelectedAnimal?.animalId
                        : undefined
                }
                closeModalHandler={closeModalHandler}
                showModal={createMoveFromHospitalPenModal.isOpen}
                selectedAnimal={currentlySelectedAnimal}
                penFilter={penFilter}
            />
            <Row
                className={`table-scroll-div ${
                    hospitalAnimalsList.length > Constants.showMaxViewLimit
                        ? 'white-content-block-min-height'
                        : ''
                }`}
            >
                <Accordion
                    className="list-group-item-primary p-0"
                    activeKey={activeAccordionKey}
                    onSelect={handleAccordionToggle}
                >
                    {hospitalAnimalsList?.map((item, index) => {
                        return (
                            <Accordion.Item
                                eventKey={index.toString()}
                                key={index}
                            >
                                <Accordion.Button
                                    as={Card.Header}
                                    className="text-secondary"
                                    onClick={() =>
                                        handleAccordionFetchData(item.animalId)
                                    }
                                >
                                    <div className="row col-12 cursor-pointer">
                                        <div className="col-2 ps-4 pt-2">
                                            {item.currentPenName}
                                        </div>
                                        <div className="col-2 ps-4 pt-2">
                                            {item.lotName}
                                        </div>
                                        <div className="col-2 ps-4 pt-2">
                                            {item.animalTag}
                                        </div>
                                        <div className="col-2 ps-4 pt-2">
                                            {item.homePenName}
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <Button
                                                className="p-2 secondary me-4"
                                                onClick={e => {
                                                    openMoveModal(item);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {
                                                    HospitalConstants[
                                                        language.value
                                                    ].button.move.label
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Accordion.Button>
                                <Accordion.Body className="bg-light">
                                    <Card.Body>
                                        <div className="flex-row">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h3 className="text-center m-0">
                                                    {item.animalTag} -{' '}
                                                    {
                                                        HospitalConstants[
                                                            language.value
                                                        ].history.adminHistory
                                                            .label
                                                    }
                                                </h3>
                                            </div>
                                        </div>
                                        <Table borderless striped>
                                            <HospitalAdminHistoryHeader />
                                            <tbody>
                                                {loadingHistory ? (
                                                    <AccordionLoading
                                                        className="d-flex justify-content-end"
                                                        spinnerColor="text-secondary"
                                                    />
                                                ) : (
                                                    animalAdminHistory.map(
                                                        (item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {formatDate_deprecated(
                                                                        item.treatmentDate,
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.diagnosisName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.protocolName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.riderName
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                    <span
                        ref={containerRef as LegacyRef<HTMLTableRowElement>}
                    />
                    {loading && (
                        <AccordionLoading
                            className="d-flex"
                            spinnerColor="text-secondary"
                        />
                    )}
                </Accordion>
            </Row>
        </>
    );
};

export default HospitalListTable;
