import { Link } from 'react-router-dom';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';

export default function LogInLogOutComponent() {
    const { isAuthenticated, login, logout } = useAuthContext();

    return (
        <li onClick={isAuthenticated() ? logout : login}>
            <Link to={`/`} className="nav-link">
                {isAuthenticated() ? 'Log Out' : 'Log In'}
            </Link>
        </li>
    );
}
