import { createSlice } from '@reduxjs/toolkit';
import { CREATE_SHIP_CATTLE_INITIAL_STATE } from '../Constants/createShipCattleInitialState';

const shipCattleSlice = createSlice({
    name: 'shipCattle',
    initialState: CREATE_SHIP_CATTLE_INITIAL_STATE,
    reducers: {
        setShipCattleBasicDetail: (state, action) => {
            state.shipCattleBasicDetail = action.payload;
        },
        setShipCattleBasicDetailError: (state, action) => {
            state.shipCattleBasicDetailError = action.payload;
        },
        setShipCattleLots: (state, action) => {
            state.lots = action.payload;
        },
        setShipCattleLot: (state, action) => {
            state.shipCattleLot = action.payload;
        },
        setShipCattleLotError: (state, action) => {
            state.shipCattleLotError = action.payload;
        },
        setShipCattleLoadTickets: (state, action) => {
            state.shipCattleLoadTickets = action.payload;
        },
        setShipCattleLoadTicketsError: (state, action) => {
            state.shipCattleLoadTicketsError = action.payload;
        },
        setShipCattleFees: (state, action) => {
            state.shipCattleFees = action.payload;
        },
        setShipCattleSummaryAndCostTotals: (state, action) => {
            state.shipCattleSummaryAndCostTotals = action.payload;
        },
        setShipGetPens: (state, action) => {
            state.pens = action.payload;
        },
        fetchShipCattleLocation: (state, action) => {
            state.locations = action.payload;
        },
        setShipCattleFeesError: (state, action) => {
            state.shipCattleFeesError = action.payload;
        },
        setCostFeeTotal: (state, action) => {
            state.costFeeTotalAmount = action.payload;
        },
        setLoadTicketTotal: (state, action) => {
            state.loadTicketsHdCountTotal = action.payload;
        },
        setGeneratedInvoiceCutoffDate: (state, action) => {
            state.generatedInvoiceCutoffDate = action.payload;
        },
        setFirstAllowedDateBasedOnCutoff: (state, action) => {
            state.firstAllowedDateBasedOnCutoff = action.payload;
        },
        setShipCattleInitialState: (state, action) => {
            Object.assign(state, action.payload);
        },
        appendShipCattleSummaryAndCostTotals: (state, action) => {
            state.shipCattleSummaryAndCostTotals = {
                ...state.shipCattleSummaryAndCostTotals,
                ...action.payload,
            };
        },
        setShipCattleLoading: (state, action) => {
            state.loading = action.payload;
        },
        setShipCattleReports: (state, action) => {
            state.shipCattleReports = action.payload;
        },
        setShipCattleLotBackdatedHdCount: (state, action) => {
            state.shipCattleLot = {
                ...state.shipCattleLot,
                backdatedHeadCount: action.payload,
            };
        },
        setShipSubmitPending: (state, action) => {
            state.submitPending = action.payload;
        },
    },
});

export const shipCattleActions = shipCattleSlice.actions;
export const shipCattleReducer = shipCattleSlice.reducer;
