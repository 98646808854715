import { LegacyRef, useEffect } from 'react';
import { useElementOnScreen } from '../../commonHooks/useElementOnScreen';
import Constants from '../../utils/Constants';
import { IDesktopTableBody } from '../../utils/Interface/CommonInterface';
import TableRowLoading from '../common/TableRowLoading';
import DesktopTableRow from './DesktopTableRow';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA9612On_ScrollHandlerScreenSize } = feedlotManagerFeatures;

const DesktopTableBody = ({
    handleScroll,
    tableRows,
    loading,
    tableConstants,
    headCellCount,
    actions,
    permission,
}: IDesktopTableBody) => {
    const [containerRef, isVisible] = useElementOnScreen({
        ...Constants.useElementOnScreen.options,
    });

    const CA9612RowLengthCondition = isCA9612On_ScrollHandlerScreenSize
        ? tableRows.length
        : undefined;

    useEffect(() => {
        if (isVisible) {
            handleScroll();
        }
    }, [isVisible, CA9612RowLengthCondition]);

    return (
        <tbody>
            {tableRows.length === 0 && !loading ? (
                <tr>
                    <td colSpan={headCellCount} className="text-center">
                        {Constants.customMessage.NO_RECORDS_FOUND}
                    </td>
                </tr>
            ) : (
                tableRows?.map((row, index) => (
                    <DesktopTableRow
                        key={index}
                        rowIndex={index}
                        tableRow={row}
                        tableConstants={tableConstants}
                        actions={actions}
                        permission={permission}
                    />
                ))
            )}
            <tr ref={containerRef as LegacyRef<HTMLTableRowElement>}></tr>
            {loading && <TableRowLoading colspan={headCellCount} />}
        </tbody>
    );
};

export default DesktopTableBody;
