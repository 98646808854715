import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { currentDate, currentDate_deprecated } from '../../helpers';
import { IPenRationTemplate } from '../../utils/Interface/PenRationTemplatesInterface';

/*
 * Create Pen Ration Schedule Templates Initial States
 */
const { isCA8929On_LocalUTCDateTimeFix } = feedlotManagerFeatures;
export const PEN_RATION_TEMPLATES_INITIAL_STATE: IPenRationTemplate = {
    rationTemplates: [],
    existingRationTemplates: [{ name: 'Select Ration Template', id: 0 }],
    existingRationTemplatesDetails: [],
    loading: false,
    filter: { limit: 20, offset: 0 },
    showModal: false,
    scheduleTypes: [{ name: 'Select Schedule Type', id: 0 }],
    editPenRationTemplateBasicDetail: {
        id: 0,
        templateName: '',
        scheduleTypeId: 0,
        startingDryMatterPerHd: '',
        startingAFPerHd: '',
    },
    editPenRationTemplateBasicDetailError: {
        templateName: false,
        scheduleTypeId: false,
        startingDryMatterPerHd: false,
        startingAFPerHd: false,
    },
    rations: [],
    editPenRationTemplate: {
        id: '',
        splitRationSegmentOrderNumber: 1,
        splitScheduleId: '',
        ration1: '',
        ration2: '',
        percentRation2: '',
        percentRation2Incr: '',
        date: isCA8929On_LocalUTCDateTimeFix
            ? currentDate('YYYY-MM-DD')
            : currentDate_deprecated('YYYY-MM-DD'),
        days: 1,
        feedings: 1,
    },
    editPenRationTemplates: [
        {
            id: '',
            splitRationSegmentOrderNumber: 1,
            splitScheduleId: '',
            ration1: '',
            ration2: '',
            percentRation2: '',
            percentRation2Incr: '',
            date: isCA8929On_LocalUTCDateTimeFix
                ? currentDate('YYYY-MM-DD')
                : currentDate_deprecated('YYYY-MM-DD'),
            days: '',
            feedings: 1,
        },
    ],
    editPenRationTemplateError: {
        ration1: false,
        ration2: false,
        percentRation2: false,
        percentRation2Incr: false,
        date: false,
        days: false,
        feedings: false,
    },
    editPenRationTemplatesError: [
        {
            ration1: false,
            ration2: false,
            percentRation2: false,
            percentRation2Incr: false,
            date: false,
            days: false,
            feedings: false,
        },
    ],
    variableSplitRationTemplate: {
        id: '',
        variableSplitId: '',
        feeding1: '',
        feeding2: '',
        feeding3: '',
        rationPercentage1: '',
        rationPercentage2: '',
        rationPercentage3: '',
        percentageChange1: '',
        percentageChange2: '',
        percentageChange3: '',
        days: 1,
        isDeleted: false,
    },
    variableSplitRationTemplates: [
        {
            id: '',
            variableSplitId: '',
            feeding1: '',
            feeding2: '',
            feeding3: '',
            rationPercentage1: '',
            rationPercentage2: '',
            rationPercentage3: '',
            percentageChange1: '',
            percentageChange2: '',
            percentageChange3: '',
            days: 1,
            isDeleted: false,
        },
    ],
    variableSplitTemplateError: {
        feeding1: false,
        feeding2: false,
        feeding3: false,
        rationPercentage1: false,
        rationPercentage2: false,
        rationPercentage3: false,
        percentageChange1: false,
        percentageChange2: false,
        percentageChange3: false,
        date: false,
        days: false,
    },
    variableSplitTemplatesError: [
        {
            feeding1: false,
            feeding2: false,
            feeding3: false,
            rationPercentage1: false,
            rationPercentage2: false,
            rationPercentage3: false,
            percentageChange1: false,
            percentageChange2: false,
            percentageChange3: false,
            date: false,
            days: false,
        },
    ],
    selectedScheduleType: { scheduleTypeId: 0 },
};
