import { CREATE_SHIP_CATTLE_REALIZER_INITIAL_STATE } from '../Constants/createShipCattleRealizerInitialState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    IShipCattleRealizerBasicDetail,
    IFilter,
    IShipCattleRealizerBasicDetailError,
    IShipCattleRealizerSummary,
    ILot,
    ISex,
    IShipCattleRealizerShipment,
    IShipCattleRealizerSummaryError,
    IShipCattleRealizerShipmentError,
    IAnimalId,
    IReasons,
    ICreateReasonModalDetail,
    ICreateReasonModalDetailError,
    IDestination,
    IShipCattleRealizerHistoryDetail,
    IUpdatedRealizerDetail,
    IShipCattleListItem,
    IOwner,
} from '../../utils/Interface/ShipCattleInterface';
import { ICsvData } from '../../utils/Interface/CommonInterface';

export const ShipCattleRealizerReducer = createSlice({
    name: 'ShipCattleRealizer',
    initialState: { ...CREATE_SHIP_CATTLE_REALIZER_INITIAL_STATE },
    reducers: {
        setShipCattleRealizerIsCompleted: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isCompleted = action.payload;
        },
        setShipCattleRealizerHistoryList: (
            state,
            action: PayloadAction<IShipCattleRealizerHistoryDetail[]>,
        ) => {
            state.realizerHistory = action.payload;
        },
        appendShipCattleRealizerHistoryList: (
            state,
            action: PayloadAction<IShipCattleRealizerHistoryDetail[]>,
        ) => {
            state.realizerHistory = [
                ...state.realizerHistory,
                ...action.payload,
            ];
        },
        setShipCattleRealizerBasicDetail: (
            state,
            action: PayloadAction<IShipCattleRealizerBasicDetail>,
        ) => {
            state.shipCattleRealizerBasicDetail = action.payload;
        },
        setShipCattleRealizerLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.realizerLoading = action.payload;
        },
        setShipCattleHistoryLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.shipCattleHistoryLoading = action.payload;
        },
        setDestination: (state, action: PayloadAction<IDestination[]>) => {
            state.destination = action.payload;
        },
        setOwners: (state, action: PayloadAction<IOwner[]>) => {
            state.owners = action.payload;
        },
        setShipCattleRealizerCanCallApi: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.realizerCanCallApi = action.payload;
        },
        setShipCattleRealizerFilter: (
            state,
            action: PayloadAction<Partial<IFilter>>,
        ) => {
            state.realizerFilter = {
                ...state.realizerFilter,
                ...action.payload,
            };
        },
        setShipCattleRealizerBasicDetailError: (
            state,
            action: PayloadAction<IShipCattleRealizerBasicDetailError>,
        ) => {
            state.shipCattleRealizerBasicDetailError = action.payload;
        },
        setShipCattleRealizerSummary: (
            state,
            action: PayloadAction<IShipCattleRealizerSummary>,
        ) => {
            state.shipCattleRealizerSummary = action.payload;
        },
        setShipCattleRealizerLots: (state, action: PayloadAction<ILot[]>) => {
            state.lots = action.payload;
        },
        setSex: (state, action: PayloadAction<ISex[]>) => {
            state.sex = action.payload;
        },
        setShipCattleRealizerShipment: (
            state,
            action: PayloadAction<IShipCattleRealizerShipment[]>,
        ) => {
            state.shipCattleRealizerShipment = action.payload;
        },
        setShipCattleRealizerSummaryError: (
            state,
            action: PayloadAction<IShipCattleRealizerSummaryError>,
        ) => {
            state.shipCattleRealizerSummaryError = action.payload;
        },
        setShipCattleRealizerShipmentError: (
            state,
            action: PayloadAction<IShipCattleRealizerShipmentError[]>,
        ) => {
            state.shipCattleRealizerShipmentError = action.payload;
        },
        setAnimalIDs: (state, action: PayloadAction<IAnimalId[]>) => {
            state.animalIds = action.payload;
        },
        setReasons: (state, action: PayloadAction<IReasons[]>) => {
            state.reasons = action.payload;
        },
        setCreateReasonModalDetail: (
            state,
            action: PayloadAction<ICreateReasonModalDetail>,
        ) => {
            state.createReasonModalDetails = action.payload;
        },
        setCreateReasonModalDetailError: (
            state,
            action: PayloadAction<ICreateReasonModalDetailError>,
        ) => {
            state.createReasonModalDetailsError = action.payload;
        },
        setReasonModalResponse: (state, action: PayloadAction<number>) => {
            state.reasonModalResponse = action.payload;
        },
        setFetchedRealizerDetail: (
            state,
            action: PayloadAction<IUpdatedRealizerDetail>,
        ) => {
            state.isCompleted = action.payload.isCompleted;
            state.shipCattleRealizerShipment =
                action.payload.shipCattleRealizerShipment;
            state.shipCattleRealizerShipmentError =
                action.payload.shipCattleRealizerShipmentError;
            state.realizerLoading = action.payload.realizerLoading;
            state.shipCattleRealizerBasicDetail =
                action.payload.shipCattleRealizerBasicDetail;
            state.shipCattleRealizerSummary =
                action.payload.shipCattleRealizerSummary;
        },
        appendShipCattleList: (
            state,
            action: PayloadAction<IShipCattleListItem[]>,
        ) => {
            state.shipCattleList = [...state.shipCattleList, ...action.payload];
        },
        setShipCattleList: (
            state,
            action: PayloadAction<IShipCattleListItem[]>,
        ) => {
            state.shipCattleList = action.payload;
        },
        setRealizerHistoryReportsData: (
            state,
            action: PayloadAction<ICsvData[]>,
        ) => {
            state.realizerHistoryReportsData = action.payload;
        },
        setCanCallShipmentApi: (state, action: PayloadAction<boolean>) => {
            state.canCallShipmentApi = action.payload;
        },
        setShipCattleRealizerSubmitPending: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.submitPending = action.payload;
        },
    },
});

export const {
    setShipCattleRealizerIsCompleted,
    setShipCattleRealizerHistoryList,
    appendShipCattleRealizerHistoryList,
    setShipCattleRealizerBasicDetail,
    setShipCattleRealizerLoading,
    setShipCattleHistoryLoading,
    setDestination,
    setOwners,
    setFetchedRealizerDetail,
    setShipCattleRealizerCanCallApi,
    setShipCattleRealizerFilter,
    setShipCattleRealizerBasicDetailError,
    setShipCattleRealizerSummary,
    setShipCattleRealizerLots,
    setShipCattleRealizerShipment,
    setShipCattleRealizerSummaryError,
    setShipCattleRealizerShipmentError,
    setAnimalIDs,
    setReasons,
    setSex,
    setCreateReasonModalDetail,
    setCreateReasonModalDetailError,
    setReasonModalResponse,
    setShipCattleList,
    appendShipCattleList,
    setRealizerHistoryReportsData,
    setCanCallShipmentApi,
    setShipCattleRealizerSubmitPending,
} = ShipCattleRealizerReducer.actions;

const realizerHistory = ShipCattleRealizerReducer.reducer;

export default realizerHistory;
