import { useState } from 'react';
import { Roles, SideNavItems } from '../../utils/constants/RoleConstants';
import SideNavTabButton from './SideNavTabButton';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { IGenericSetState } from '../../utils/Interface/CommonInterface';

const SideNavTab = ({
    setSideNavTab,
}: {
    setSideNavTab: IGenericSetState<string | number>;
}) => {
    const [active, setActive] = useState<string | number>('');
    const {
        getUsersAppSubscriptions,
        isAuthenticated,
        getUsersRoles,
        hasTenantId,
    } = useAuthContext();
    const handleActive = (data: number | string) => {
        setActive(data);
    };
    const usersAppSubscriptions = getUsersAppSubscriptions();
    const usersRoles = getUsersRoles();

    return (
        <div className="sidebar-tabs d-flex justify-content-between">
            {isAuthenticated() &&
                hasTenantId() &&
                Object.values(SideNavItems)
                    .filter(item => item.isAppEnabled)
                    .filter(
                        item =>
                            usersRoles.includes(Roles.ADMIN) ||
                            usersAppSubscriptions.includes(item.appRole),
                    )
                    .filter(item =>
                        item.roles.some(role => usersRoles.includes(role)),
                    )
                    .map((item, index) => (
                        <SideNavTabButton
                            key={index}
                            active={active === index}
                            handleActive={handleActive}
                            usersRoles={usersRoles}
                            id={index}
                            item={item}
                            setSideNavTab={setSideNavTab}
                        />
                    ))}
        </div>
    );
};

export default SideNavTab;
