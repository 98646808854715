import { AnyAction } from '@reduxjs/toolkit';
import { ICsvData } from '../../utils/Interface/CommonInterface';

import { ActionTypes } from '../Actions';
import { CREATE_HOTLIST_INITIAL_STATE } from '../Constants/hotListInitialState';

const HotListReducer = (
    state = CREATE_HOTLIST_INITIAL_STATE,
    action: AnyAction,
) => {
    let stateCopy = { ...state };

    switch (action.type) {
        case ActionTypes.SET_HOTLIST_LOADING:
            stateCopy = {
                ...stateCopy,
                loading: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_HOTLIST_DETAIL:
            stateCopy = {
                ...stateCopy,
                hotListDetail: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_COMPLETE_HOTLIST:
            stateCopy = {
                ...stateCopy,
                completeHotList: action.payload as ICsvData[],
            };
            return stateCopy;
        case ActionTypes.SET_HOTLIST_FILTER:
            stateCopy = {
                ...stateCopy,
                filter: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_LOTS_FILTER:
            stateCopy = {
                ...stateCopy,
                lots: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_HOTLIST_CAN_CALL_API:
            stateCopy = {
                ...stateCopy,
                canCallApi: action.payload,
            };
            return stateCopy;

        default:
            return stateCopy;
    }
};

export default HotListReducer;
