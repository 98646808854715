import { useLanguage } from '../../commonHooks/useLocalization';
import { HospitalConstants } from '../../utils/constants/HospitalConstants';

const HospitalAccordionHeader = () => {
    const language = useLanguage();

    return (
        <thead>
            <tr>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .hospitalPen.label
                    }
                </th>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader.lot
                            .label
                    }
                </th>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .animalId.label
                    }
                </th>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .homePen.label
                    }
                </th>
            </tr>
        </thead>
    );
};

export default HospitalAccordionHeader;
