import { useEffect, useState } from 'react';
import { useAppSelector } from '../Redux/Store';

const useFeedlotId = () => {
    const [feedlotId, setFeedlotId] = useState<number>();
    const selectedFeedlot = useAppSelector(
        state => state.common.selectedFeedlot,
    );

    useEffect(() => {
        setFeedlotId(selectedFeedlot.id);
    }, [selectedFeedlot]);

    return feedlotId;
};

export { useFeedlotId };
