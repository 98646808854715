import { Spinner } from 'react-bootstrap';

export default function FullPageLoader(props: {
    className?: string;
    newClass?: string;
}) {
    return (
        <div
            className={`mainLoading d-flex flex-column justify-content-center align-items-center ${
                props.newClass || ''
            }`}
            {...props}
        >
            <div>
                <Spinner
                    animation="border"
                    data-size="lg"
                    className="text-center"
                />
            </div>
        </div>
    );
}
