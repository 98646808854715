import { Table } from 'react-bootstrap';
import DesktopTableBody from './DesktopTableBody';
import DesktopTableHeader from './DesktopTableHeader';
import { IDesktopTable } from '../../utils/Interface/CommonInterface';
import Constants from '../../utils/Constants';

const DesktopTable = ({
    handleScroll,
    tableRows,
    loading,
    tableConstants,
    actions,
    permission = true,
}: IDesktopTable) => {
    return (
        <div
            className={`table-responsive d-md-block d-none table-scroll-div ${
                tableRows.length > Constants.showMaxViewLimit
                    ? 'white-content-block-min-height'
                    : ''
            }`}
        >
            <Table borderless striped>
                <DesktopTableHeader tableConstants={tableConstants} />
                <DesktopTableBody
                    headCellCount={Object.keys(tableConstants.headers).length}
                    tableRows={tableRows}
                    loading={loading}
                    tableConstants={tableConstants}
                    actions={actions}
                    permission={permission}
                    handleScroll={handleScroll}
                />
            </Table>
        </div>
    );
};

export default DesktopTable;
