import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { currentDate_deprecated } from '../../helpers';
import { IShipCattleRealizerInitialState } from '../../utils/Interface/ShipCattleInterface';
import { shipCattleLabelConstants } from '../../utils/LabelConstant';

const { isCA4879On_CloseALot } = feedlotManagerFeatures;

export const CREATE_SHIP_CATTLE_REALIZER_INITIAL_STATE: IShipCattleRealizerInitialState =
    {
        isCompleted: false,
        realizerHistory: [],
        realizerHistoryReportsData: [],
        realizerLoading: false,
        shipCattleHistoryLoading: false,
        realizerCanCallApi: true,
        sex: [{ name: shipCattleLabelConstants.Sex.SELECT_SEX, sexId: 0 }],
        lots: [
            {
                name: shipCattleLabelConstants.Lots.SELECT_LOTS,
                lotId: 0,
                pens: [],
            },
        ],
        pens: [],
        animalIds: [],
        reasons: [
            {
                reasonName:
                    shipCattleLabelConstants.CattleDiedReasonId.SELECT_REASON,
                reasonId: 0,
            },
            {
                reasonName:
                    shipCattleLabelConstants.CattleDiedReasonId.CREATE_REASON,
                reasonId:
                    shipCattleLabelConstants.CattleDiedReasonId.CREATE_REASON,
            },
        ],
        destination: [
            {
                name: shipCattleLabelConstants.BuyLocation.CREATE_LOCATION,
                id: shipCattleLabelConstants.BuyLocation.CREATE_LOCATION,
            },
        ],
        owners: [],
        createReasonModalDetails: {
            reasonName: '',
        },
        createReasonModalDetailsError: {
            reasonName: false,
        },

        reasonModalResponse: 0,

        shipCattleRealizerBasicDetail: {
            date: currentDate_deprecated('YYYY-MM-DD'),
            destination: '',
        },

        shipCattleRealizerBasicDetailError: { date: '' },

        shipCattleRealizerShipment: [
            {
                realizerId: 0,
                lotId: 0,
                lotName: isCA4879On_CloseALot ? '' : undefined,
                animalId: 0,
                animalTag: '',
                penId: '',
                weight: '',
                penName: '',
                hdCount: 0,
                cattleDiedReasonId: '',
                condemned: false,
                cattleRevenue: '',
                paymentDate: '',
            },
        ],

        shipCattleRealizerShipmentError: [
            {
                lotId: '',
                animalTag: '',
                penId: '',
                weight: '',
                cattleDiedReasonId: '',
                cattleRevenue: '',
            },
        ],
        //Remove when isCA10264On_RealizerDeleteRowBug is removed
        shipment: {
            lotId: 0,
            lotName: isCA4879On_CloseALot ? '' : undefined,
            animalId: 0,
            animalTag: '',
            penId: '',
            weight: '',
            cattleDiedReasonId: '',
            condemned: false,
            cattleRevenue: '',
            paymentDate: '',
        },
        //Remove when isCA10264On_RealizerDeleteRowBug is removed
        shipmentError: {
            lotId: '',
            animalTag: '',
            penId: '',
            weight: '',
            cattleDiedReasonId: '',
            cattleRevenue: '',
        },

        shipCattleRealizerSummaryError: { brands: '' },
        shipCattleRealizerSummary: {
            headCountTotal: 0,
            payWeight: 0.0,
            totalRevenue: 0.0,
            brands: '',
        },

        realizerFilter: {
            limit: 20,
            offset: 0,
            include: 'Destination',
            date: '',
        },
        shipCattleList: [],
        canCallShipmentApi: false,
        submitPending: false,
    };
