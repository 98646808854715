import { FC } from 'react';
import { cattlePO } from '../../apps/shippingTracker/featureFlags/cattlePOFeatures';

const SidebarLogoNGAT: FC = props => {
    const { isBP828On_inconsistentLogoBug } = cattlePO;
    const logo = isBP828On_inconsistentLogoBug
        ? 'sidebar_ngat.png'
        : 'sidebar_ngat_old.png';
    return (
        <img
            {...props}
            alt="sidebar_ngat.png"
            src={require(`./${logo}`)}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
        />
    );
};

export default SidebarLogoNGAT;
