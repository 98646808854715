import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import HospitalListTable from '../hospital/HospitalListTable';
import { ChangeEvent, useEffect, useState } from 'react';
import HospitalAccordionHeader from '../hospital/HospitalAccordionHeader';
import HospitalFilter from '../hospital/HospitalFilter';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import {
    fetchAnimalsHospital,
    fetchHospitalPens,
} from '../../Redux/Actions/hospitalActions';

const Hospital = () => {
    const { hospitalPens } = useAppSelector(state => state.hospital);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const [activeAccordion, setActiveAccordion] =
        useState<AccordionEventKey>('');
    const [penFilter, setPenFilter] = useState('');

    const handleAccordionToggle = (key: AccordionEventKey) => {
        setActiveAccordion(key);
    };

    const filterHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setPenFilter(e.target.value);
    };

    useEffect(() => {
        if (feedlotId) {
            dispatch(fetchAnimalsHospital(feedlotId, penFilter));
            dispatch(fetchHospitalPens(feedlotId));
        }
    }, [dispatch, feedlotId, penFilter]);

    return (
        <div className="pt-4">
            <section className="main-content">
                <div className="main-content-block">
                    <div className="white-content-block">
                        <HospitalFilter
                            feedlotId={feedlotId}
                            filterHandler={filterHandler}
                            penFilter={penFilter}
                            hospitalPens={hospitalPens}
                        />
                        <div className="m-4">
                            <div className="col-8 mb-3">
                                <HospitalAccordionHeader />
                            </div>
                            <HospitalListTable
                                activeAccordionKey={activeAccordion}
                                onAccordionToggle={handleAccordionToggle}
                                penFilter={penFilter}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Hospital;
