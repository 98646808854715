import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { currentDate, currentDate_deprecated } from '../../helpers';
import { IFeedStatusInitialState } from '../../utils/Interface/FeedStatusInterface';
import { feedStatusConstant } from '../../utils/LabelConstant';

const { isCA8929On_LocalUTCDateTimeFix } = feedlotManagerFeatures;

export const FEED_STATUS_INITIAL_STATE: IFeedStatusInitialState = {
    penInformation: [],
    loadInformation: [],
    filter: {
        rationName: '',
        date: isCA8929On_LocalUTCDateTimeFix
            ? currentDate('YYYY-MM-DD')
            : currentDate_deprecated('YYYY-MM-DD'),
        truckDriver: '',
        feedingNumber: '',
        limit: 20,
        offset: 0,
    },
    loadedCommodities: [],
    rations: [{ rationName: feedStatusConstant.Rations.ALL_RATIONS }],
    feedings: [{ feedingNumber: feedStatusConstant.Feedings.ALL_FEEDINGS }],
    drivers: [
        {
            truckDriver: feedStatusConstant.Drivers.ALL_DRIVERS,
            driver: feedStatusConstant.Drivers.ALL_DRIVERS,
        },
    ],
    selectedFeedTaskId: '',
    loading: true,
    canCallApi: true,
};
