export const MoveFromHospitalPenModalConstants = {
    en: {
        cancel: 'Cancel',
        move: 'Move',
        selectPen: 'Select Pen',
        moveToPen: 'Move to Pen',
        homePen: 'Home Pen',
        animalId: 'Animal Id',
        hospitalPen: 'Hospital Pen',
        moveDate: 'Move Date',
        moveFromHospitalPen: 'Move from Hospital Pen',
        lot: 'Lot',
        successMessage: 'Animal moved successfully',
        noHomePensMessage:
            'This Lot doesn\'t have any cattle in a home pen. All pens in the Feedlot are displayed in the "Move to Pen" dropdown.',
    },
    es: {
        cancel: 'Cancelar',
        move: 'Mover',
        selectPen: 'Seleccionar pluma',
        moveToPen: 'Mover a pluma',
        homePen: 'Hogar del corral',
        animalId: 'Identificación de animales',
        hospitalPen: 'Corral de Hosptial',
        moveDate: 'Fecha de mudanza',
        moveFromHospitalPen: 'Mover desde corral de hospital',
        lot: 'Lote',
        successMessage: 'Animal moved successfully',
        noHomePensMessage:
            'Este Lote no tiene ganado en un hogar del corral. Todos los corrales en el Feedlot se muestran en el menú desplegable "Mover a pluma."',
    },
};
